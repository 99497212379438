"use client"

import {createContext, ReactNode, useEffect, useState} from 'react';
import 'firebase/analytics';
import {Analytics, getAnalytics, logEvent} from "@firebase/analytics";
import {app} from "../../firebase/config";
import {usePathname} from "../../i18n/routing";

export const FirebaseContext = createContext<Analytics|null>(null);

export const FirebaseTrackingProvider = (props: { children: ReactNode }) => {
    const pathname = usePathname();
    const [tracking, setTracking] = useState<Analytics | null>(null);

    useEffect(() => {
        if (!tracking) setTracking(getAnalytics(app))
    }, [tracking]);

    useEffect(() => {
        if (!tracking) {
            return;
        }
        logEvent(tracking, 'page_view', {
            // page_location: pathname,
            page_title: document?.title,
            page_path: pathname,
            page_location: pathname
        })
        console.log(`page_view: ${pathname}`)
    }, [pathname, tracking]);

    return (
        <FirebaseContext.Provider value={tracking}>
            {props.children}
        </FirebaseContext.Provider>
    );
};